import { graphql } from 'gatsby'
import React, { useEffect } from "react"
import { confirmAlert } from 'react-confirm-alert'
import { getCatalogFilteredItems, getSiteConfigField, isSymphony, setBgColor, setFontFace, setNavBarBgColor, slugify, titleIfy } from '../../utils/helpers'
import { isBrowser, setPageProperties } from '../../utils/zeotap'
import { Center, DisplayMedium, DisplaySmall, Showcase } from '../components'
import CartLink from '../components/CartLink'
import SetConsentModal from '../components/formComponents/SetConsentModal/SetConsentModal'
import LogLink from '../components/LogLink'
import SEO from "../components/seo"
import CategoryView from '../templates/CategoryView'

const Home = ({ data: gqlData ,...rest}) => {
  let { inventoryInfo, categoryInfo } = gqlData;
  inventoryInfo.data = getCatalogFilteredItems(inventoryInfo.data);
  categoryInfo.data = getCatalogFilteredItems(categoryInfo.data);

  const categories = categoryInfo.data.slice(0, 2)
  const inventory = inventoryInfo.data.slice(0, 4)
  const {searchText} = rest;
  setPageProperties({ page_category: 'Home', page_name: 'Home', page_domain: isBrowser() && window.location.hostname })

  useEffect(() => {
    setFontFace(getSiteConfigField('fontFamilyUrl'));
    setBgColor(getSiteConfigField('bgColor'));
    setNavBarBgColor(getSiteConfigField('navbarColor'));
    isBrowser() && window.zeotap && window.zeotap.addAskForConsentActionListener && window.zeotap.addAskForConsentActionListener(() => {
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <SetConsentModal onClose={onClose} />
          )
        },
        closeOnEscape: false,
        closeOnClickOutside: false
      });
    })
  }, []);
  return (
    <>
      <CartLink />
      <LogLink />
      <SEO title="Home" />
      { searchText ? <CategoryView {...rest} pageContext={{content: {items: inventoryInfo.data}}}></CategoryView>:
      <><div className="w-full">
        <div className="bg-green-200
        lg:h-hero
        p-6 pb-6 smpb-6
        flex lg:flex-row flex-col">
          <div className="pt-4 pl-2 sm:pt-12 sm:pl-12 flex flex-col">
            <Center
              price={inventory[2].price}
              title={inventory[2].name}
              link={`/${slugify(inventory[2].name)}`}
            />
          </div>
          <div className="flex showcase-container justify-center items-center relative">
            <Showcase
              imageSrc={inventory[2].image}
            />
            <div className="absolute
              w-48 h-48 sm:w-72 sm:h-72 xl:w-88 xl:h-88
              bg-white z-0 rounded-full" />
          </div>
        </div>
      </div>
      <div className="my-4 lg:my-8 flex flex-col lg:flex-row justify-between">
        {categories.map((category, i) => {
          return <DisplayMedium key={i} imageSrc={category.image} subtitle={`${category.itemCount} items`} title={titleIfy(category.name)} link={slugify(category.catalog) + '-' + slugify(category.name)} />
        })
        }
      </div>
      {!isSymphony() && <div className="pt-10 pb-6 flex flex-col items-center">
        <h2 className="text-4xl mb-3">Trending Now</h2>
      </div>}
      {!isSymphony() && <div className="my-8 flex flex-col lg:flex-row justify-between">
        {
          inventory.map((inventory, i) => {
            if (i < 4)
              return (<DisplaySmall key={i} id={inventory.id} imageSrc={inventory.image} title={inventory.name} subtitle={inventory.categories[0]} link={slugify(inventory.name)} />)
            return null
          })
        }
      </div>}</>
    }</>
  )
}

export const pageQuery = graphql`
  query {
    navInfo {
      data {
        name
        catalog
      }
    }
    categoryInfo {
      data {
        name
        image
        itemCount
        catalog
      }
    }
    inventoryInfo {
      data {
        catalog
        image
        name
        brand
        price
        color
        categories
        description
        id
        currentInventory
        vehicle_color
        vehicle_model
        vehicle_submodel
        vehicle_engine
        vehicle_transmission
        vehicle_trim
        vehicle_brand
        vehicle_ecolabel
        vehicle_type
        vehicle_emissionstandard
        vehicle_power
        vehicle_torque
        vehicle_fuelcapacity
        vehicle_seatingcapacity
        vehicle_luggagecapacity
        vehicle_tiresize
        vehicle_maxspeed
        vehicle_acceleration
        vehicle_fuelconsumption
        vehicle_weight
        material
      }
    }
  }
`
export default Home

